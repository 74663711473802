import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Mail, Phone } from 'react-feather'

interface Props {
  email?: string
  languageCode: string
  languagePrefix: string | null
  phone?: string
  whatsapp?: string
}

export const Toolbars = memo(function Toolbars({
  languageCode,
  languagePrefix,
  email,
  phone,
  whatsapp,
}: Props) {
  const [scrollDir, setScrollDir] = useState('up')

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <Container
      dial={5}
      row
      space="between"
      className={scrollDir == 'down' ? 'hidden' : ''}
    >
      {whatsapp ? (
        <Item
          href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
          rel="noreferrer"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path d="M11.665,9.588c-.2-.1-1.178-.578-1.36-.644s-.315-.1-.449.1-.514.643-.629.775-.233.14-.431.05a5.411,5.411,0,0,1-1.6-.99A6.048,6.048,0,0,1,6.088,7.5c-.116-.2-.013-.31.087-.41s.2-.23.3-.349a1.69,1.69,0,0,0,.2-.331.366.366,0,0,0-.017-.349c-.05-.1-.448-1.08-.615-1.471s-.325-.34-.448-.34-.247-.01-.381-.01a.752.752,0,0,0-.531.239,2.226,2.226,0,0,0-.7,1.65A3.9,3.9,0,0,0,4.8,8.179a8.889,8.889,0,0,0,3.4,2.99c.476.2.847.32,1.136.419a2.758,2.758,0,0,0,1.253.081,2.052,2.052,0,0,0,1.344-.951,1.65,1.65,0,0,0,.12-.95c-.049-.09-.18-.14-.38-.23M8.041,14.5H8.03a6.669,6.669,0,0,1-3.37-.92l-.24-.143-2.5.65.67-2.43-.159-.25a6.612,6.612,0,0,1,10.3-8.157,6.525,6.525,0,0,1,1.939,4.66A6.62,6.62,0,0,1,8.045,14.5M13.68,2.3A8.1,8.1,0,0,0,8.03,0a7.926,7.926,0,0,0-6.9,11.892L0,16l4.223-1.1a8.041,8.041,0,0,0,3.807.965h0A7.96,7.96,0,0,0,16,7.933a7.863,7.863,0,0,0-2.33-5.607" />
          </svg>
          <Label>{useVocabularyData('whatsapp', languageCode)}</Label>
        </Item>
      ) : null}

      {email ? (
        <Item
          href={`mailto: ${email}`}
          onClick={() => {
            typeof window.gtag !== 'undefined' &&
              window.gtag('event', 'Click', {
                event_category: 'Website',
                event_label: 'Email Address',
              })
          }}
        >
          <Mail />
          <Label>{useVocabularyData('email', languageCode)}</Label>
        </Item>
      ) : null}

      {phone ? (
        <Item
          href={`tel:${phone}`}
          onClick={() => {
            if (isMobile) {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Mobile Phone',
                })
            }
          }}
        >
          <Phone />
          <Label>{useVocabularyData('telephone', languageCode)}</Label>
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 3px 60px;
  padding: 14px 3rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  transition: 0.3s ease-out;
  &.hidden {
    transform: translateY(6rem);
  }
`

const Item = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  flex: 1;
  position: relative;
  text-align: center;
  &:after {
    content: '';
    width: 1px;
    height: 24px;
    background: ${({ theme }) => theme.colors.variants.neutralLight0};
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  &:first-of-type {
    svg {
      stroke: none;
      fill: ${({ theme }) => theme.colors.variants.neutralDark4};
    }
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }

  svg {
    width: auto;
    height: 16px;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark4};
    stroke-width: 2;
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
  margin-top: 0.25rem;
`
