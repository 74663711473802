import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  childs?: ChildsProps[]
  className?: string
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  url?: string
}

interface ChildsProps {
  id?: string
  title: string
  url: string
  weight?: number
}

export const MenuItem = memo(function MenuItem({
  childs,
  className,
  title,
  url,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <>
      {url ? (
        <Anchor className={className} to={url} activeClassName="active">
          {title}
        </Anchor>
      ) : (
        <NoLink className={className}>{title}</NoLink>
      )}
      {childs && childs.length > 0 ? (
        <Submenu className="submenu">
          {childs
            .sort((a: any, b: any) => a.weight - b.weight)
            .map((item, index) => (
              <ChildItem key={index} {...item} />
            ))}
        </Submenu>
      ) : null}
    </>
  )
})

export const ChildItem = memo(function ChildItem({ title, url }: ChildsProps) {
  return (
    <SubenuLi>
      <Anchor to={url} activeClassName="active">
        {title}
      </Anchor>
    </SubenuLi>
  )
})

const Style = css`
  color: ${theme.colors.variants.neutralDark4};
  font-family: ${theme.fontFamily.heading};
  font-size: 3.3vh;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 6vh;
  transition: 0.2s ease-out;

  @media (max-width: 767px) {
    font-size: 26px;
    letter-spacing: normal;
    line-height: 44px;
  }
`

const Anchor = styled(Link)`
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
  }

  ${Style}
`

const NoLink = styled.span`
  cursor: default;

  ${Style}
`

const Submenu = styled.ul``

const SubenuLi = styled.li`
  margin-top: 1.3vh;
  a {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 1.4vh;
    letter-spacing: 0.5px;
    line-height: 2.2vh;
    padding-left: 0.1875rem;
    text-transform: uppercase;
  }
`
